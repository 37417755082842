import React, { InputHTMLAttributes } from "react";
import cx from "classnames";
import { ReactComponent as Warning } from "@assets/icons/warning-solid-small.svg";

type Props = { display: boolean; message: string } & JSX.IntrinsicElements["div"];

export default ({ display, message, ...props }: Props) => (
  <div
    {...props}
    className={cx(
      "-translate-y-3 transition-yspacing bg-red-100 ring-1 ring-red-100 -z-10 rounded-md",
      display ? "p-2 pt-5" : "h-0",
      props.className
    )}
  >
    <div className={cx("flex", display ? "visible" : "invisible")}>
      <Warning height={"1rem"} className="text-red-600" />
      <p className=" text-red-600 text-xs pl-2">{message}</p>
    </div>
  </div>
);
