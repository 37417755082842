import { getErrorMessage } from "./error";

type SubmitWrapperProps = {
  preSubmit?: () => void;
  postSubmit?: () => void;
  setProcessing?: (processing: boolean) => void;
  setError?: (error: string) => void;
  processing?: boolean;
};

export const submitWrapper =
  <T>(props: SubmitWrapperProps, f: (t: T) => Promise<void>) =>
  async (t: T) => {
    const { preSubmit, postSubmit, setProcessing, setError, processing } = props;

    if (processing) return;

    preSubmit?.();
    setProcessing?.(true);
    setError?.("");

    await f(t)
      .catch((e) => setError?.(getErrorMessage(e)))
      .finally(() => {
        setProcessing?.(false);
        postSubmit?.();
      });
  };
