import React, { SelectHTMLAttributes } from "react";

type Props = {
  icon?: React.ReactNode;
  options: { label: string; value: string }[];
  placeholder: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

export default React.forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const placeholderValue = "";
  const options = [{ label: props.placeholder, value: placeholderValue }, ...props.options];

  return (
    <div className="group relative flex items-center">
      {props.icon}
      <select
        ref={ref}
        {...props}
        defaultValue={placeholderValue}
        className={`${
          props.className ?? ""
        } focus:ring-2 focus:ring-blue-500 focus:outline-none w-full text-sm leading-6 text-gray-900 placeholder-gray-400 rounded-md py-2 px-2 bg-white bg-clip-padding bg-no-repeat ${
          props.icon ? "pl-11" : ""
        } ring-1 ring-gray-200 shadow-sm`}
      >
        {options.map(({ label, value }, idx) => (
          <option key={`${idx}-${value}`} value={value} {...(idx === 0 ? { disabled: true } : {})}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
});
