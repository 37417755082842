import React from "react";

import { Card, Error } from "~/components/elements";

interface Props {
  children: React.ReactNode;
}

interface state {
  error?: string;
}

export default class Catch extends React.Component<Props, state> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: any) {
    console.error(error);
    this.setState({ error: error ? error.toString() : undefined });
  }

  render() {
    return this.state.error ? (
      <Card>
        <div className="flex justify-center">
          <Error>Something went wrong: {this.state.error}</Error>
        </div>
      </Card>
    ) : (
      this.props.children
    );
  }
}
