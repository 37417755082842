const isErrorWithMessage = (error: unknown): error is { message: string } =>
  typeof error === "object" &&
  error !== null &&
  "message" in error &&
  typeof (error as Record<string, unknown>).message === "string";

export const getErrorMessage = (error: unknown, defaultMessage = "Sorry, an error occurred.") => {
  if (isErrorWithMessage(error)) return error.message;
  if (typeof error === "string") return error;
  return defaultMessage;
};
