import React from "react";

import { Link, LinkProps } from "react-router-dom";

type Props = {} & LinkProps;

export default (props: Props) => (
  <Link
    {...props}
    className={`text-blue-600 decoration-blue-600 hover:underline underline-offset-1 ${props.className ?? ""}`}
  />
);
