import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { Error as ErrorLayout } from "~/components/layouts";
import { getValueFromSearch } from "~/utils";
import { useAsyncEffect } from "~/hooks";
import { kratos } from "~/services";
import { errors } from "~/const";

// see https://www.ory.sh/docs/kratos/self-service/flows/user-facing-errors

type KratosError = {
  code: number;
  message: string;
  reason: string;
  status: string;
};

export default () => {
  const id = getValueFromSearch("id");
  const [error, setError] = useState<string>();

  useAsyncEffect(async () => {
    if (id) {
      try {
        const kratosErrorWrapper = await kratos.getError(id);
        const error = kratosErrorWrapper.error as KratosError;

        console.error("Kratos error:", error);
        Sentry.captureException(new Error(`${error.message} (reason: ${error.reason})`), {
          tags: { origin: "kratos", kratosErrorId: id, code: error.code, status: error.status },
        });

        setError(`${error?.message ?? errors.default} (Error ID: ${id} / Code: ${error.code})`);
      } catch (e: any) {
        const error = new Error(`An error occurred when processing the Kratos error: ${e?.message}`, { cause: e });
        console.error(error);
        Sentry.captureException(error, {
          tags: { kratosErrorId: id },
        });
        setError(`${errors.default} (Error ID: ${id})`);
      }
    } else {
      setError(errors.default);
    }
  });

  return <ErrorLayout goBackUrl="/" text={error} />;
};
