import React, { useEffect, useState } from "react";

import { config } from "~/const";
import { useAuth } from "~/hooks/auth";
import { kratos } from "~/services";

interface Props {
  children: React.ReactNode;
  returnTo?: string;
}

const getPrivilegeSessionRemainingTimeInMs = (authenticatedAt: Date) => {
  const now = new Date();
  const diff = now.getTime() - authenticatedAt.getTime();
  return config.privilegeSessionMaxAge - diff;
};

export default ({ children, returnTo }: Props) => {
  const reloginUrl = kratos.getReloginUrl(returnTo);

  const [authenticatedAt, setAuthenticatedAt] = useState<Date>();
  const { data: auth } = useAuth();

  let reloginRedirectTimeout: number | undefined;

  useEffect(() => {
    if (authenticatedAt) {
      clearTimeout(reloginRedirectTimeout);
      reloginRedirectTimeout = window.setTimeout(
        () => window.location.replace(reloginUrl),
        getPrivilegeSessionRemainingTimeInMs(authenticatedAt)
      );
      return () => clearTimeout(reloginRedirectTimeout);
    }
  }, [authenticatedAt]);

  useEffect(() => {
    if (auth?.session?.authenticated_at) {
      setAuthenticatedAt(new Date(auth.session.authenticated_at));
    }
  }, [auth]);

  if (!auth) {
    return null;
  }

  if (!auth.active || !auth?.session?.authenticated_at) {
    window.location.replace(reloginUrl);
    return null;
  }

  return <>{children}</>;
};
