import React from "react";

import { useNavigate } from "react-router-dom";
import { useAsyncEffect } from "~/hooks";
import { kratos } from "~/services";

export default () => {
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    await kratos.logout().catch(() => undefined);
    navigate("/login", { replace: true });
  }, []);

  return <></>;
};
