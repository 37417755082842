import React, { HTMLProps } from "react";

import { Link } from "react-router-dom";

type Props = {} & HTMLProps<HTMLAnchorElement>;

/**
 * Wrapper element for <a></a> for coherent style.
 * **Suggestion:** Use {@link Link} when navigating to internal URLs.
 */
export default (props: Props) => (
  <a
    {...props}
    className={`text-primary font-medium decoration-primary hover:underline underline-offset-1 ${
      props.className ?? ""
    }`}
  />
);
