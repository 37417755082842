import React from "react";

import { useForm } from "react-hook-form";
import { Label, Link, Spinner } from "~/components/elements";
import { routes } from "~/const";

import { ReactComponent as Mail } from "@assets/icons/mail.svg";
import { ReactComponent as Password } from "@assets/icons/password.svg";
import { ReactComponent as Warning } from "@assets/icons/warning-solid-small.svg";
import { Input, InputError, InputSubmit } from "./elements";
import cx from "classnames";

export type Fields = {
  email: string;
  password: string;
};

type Props = {
  onSubmit: (data: Fields) => void;
  formType: "login" | "register";
  showForgotPassword: boolean;
  disableSubmit: boolean;
  showSpinner: boolean;
};

export default ({ onSubmit, formType, showForgotPassword, disableSubmit, showSpinner }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Fields>();

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
      <div className="group">
        <Input
          {...register("email", { required: true })}
          autoComplete="false"
          tabIndex={0}
          placeholder="Email"
          className="h-12"
          error={!!errors.email}
          icon={
            <Mail
              height={"24px"}
              className="absolute left-2 text-zinc-400 pointer-events-none group-focus-within:text-zinc-500 z-20"
            />
          }
        />
        <InputError display={!!errors.email} message="Email is required" />
      </div>

      <div className="group">
        <Input
          {...register("password", { required: true })}
          placeholder="Password"
          type="password"
          autoComplete="false"
          tabIndex={0}
          className={cx("h-12")}
          error={!!errors.password}
          icon={
            <Password
              height={"24px"}
              className={cx("absolute left-2 text-zinc-400 pointer-events-none group-focus-within:text-zinc-500 z-20")}
            />
          }
        />
        <InputError display={!!errors.password} message="Password is required" />
      </div>
      <div className="relative">
        <InputSubmit tabIndex={0} variant="indigo" className="h-12 group" disabled={disableSubmit}>
          <div className="flex justify-center items-center gap-2">
            {showSpinner && <Spinner />}
            <Mail
              height={"24px"}
              className=" text-indigo-500 pointer-events-nonez-20 group-hover:scale-105 group-hover:translate-y-[-1px]"
            />
            {disableSubmit ? "Please wait..." : formType === "login" ? "Login with Email" : "Sign up with Email"}
          </div>
        </InputSubmit>
      </div>
      <div className="flex justify-center">
        {showForgotPassword ? (
          <Link tabIndex={0} className="text-xs" to={routes.recovery}>
            Forgot your password ?
          </Link>
        ) : undefined}
      </div>
    </form>
  );
};
