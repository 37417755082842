import { useEffect, DependencyList, EffectCallback } from "react";

/**
 * a utility function to make the code more readable when using async function in an effect
 * @param effect async effect function to run
 * @param deps dependencies that triggers an effect (Default: [])
 * @returns void
 */
export const useAsyncEffect = (
  effect: () => Promise<void>,
  deps: DependencyList = [],
  onUnmount: null | ReturnType<EffectCallback> = null
) =>
  useEffect(() => {
    effect();

    if (onUnmount) {
      return onUnmount;
    }
  }, deps);
