import React from "react";

import { config } from "~/const";
import { useAuth } from "~/hooks/auth";
import { getReturnTo } from "~/states/flow";
import { getValueFromSearch } from "~/utils";

interface Props {
  children: React.ReactNode;
  allowRefresh?: boolean;
}

export default ({ children, allowRefresh }: Props) => {
  const refresh = getValueFromSearch("refresh") === "true";

  const { data: auth } = useAuth();

  if (!auth) {
    return null;
  }

  if (auth.active && !(allowRefresh && refresh)) {
    const returnTo = getReturnTo();
    if (returnTo) {
      window.location.replace(returnTo);
      return null;
    }

    window.location.replace(config.defaultRedirect);
    return null;
  }

  return <>{children}</>;
};
