import { getValueFromSearch } from "~/utils";

export type State = {
  returnTo?: string;
};

export const getReturnTo = () => getValueFromSearch("return_to");

export const create = () => ({
  returnTo: getReturnTo(),
});
