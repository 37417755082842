import React from "react";

type props = { path: string };

export default ({ path }: props) => {
  switch (path) {
    case "/registration":
      return (
        <>
          <div className="hidden lg:block fixed top-0 right-1/2 left-0 bottom-0 -z-10 overflow-hidden  bg-grid bg-grid-left-mask after:absolute" />
          <div className="hidden lg:block fixed top-0 right-1/2 left-0 bottom-0 -z-20 overflow-hidden bg-zinc-100 border-r border-zinc-200 after:absolute" />

          <div className="fixed top-0 right-0 left-0 lg:left-1/2 bottom-0 -z-10 overflow-hidden bg-grid bg-grid-right-mask after:absolute" />
        </>
      );
    default:
      return (
        <div className="fixed top-0 right-0 left-0 bottom-0 z-10 overflow-hidden bg-grid bg-grid-center-mask after:absolute" />
      );
  }
};
