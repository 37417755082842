import React, { useRef, useState } from "react";

import { Card, Link, Error as ErrorElement, Success } from "~/components/elements";
import { Error as ErrorLayout } from "~/components/layouts";
import { routes } from "~/const";
import { kratos } from "~/services";
import { submitWrapper } from "~/utils";
import { Captcha, getCaptchaToken } from "~/components/helpers/captcha";

import { useRecoveryFlow, useStatus } from "~/hooks";
import { useStore } from "~/states";
import { RecoveryForm, RecoveryFields } from "~/components/forms";

export default () => {
  const returnTo = useStore((state) => state.returnTo);
  const status = useStatus();
  const captchaRef = useRef<Captcha>(null);

  const [flow, flowError] = useRecoveryFlow({ returnTo });
  const [processing, setProcessing] = useState(false);
  const [showForm, setShowForm] = useState(true);

  if (flowError) {
    return <ErrorLayout text={flowError} goBackUrl={window.location.pathname} />;
  }

  // Waits the flow state to be loaded before displaying anything
  if (!flow) return null;

  const submitWrapperProps = {
    setProcessing,
    processing,
    setError: status.setError,
  };

  const onSubmit = submitWrapper(submitWrapperProps, async (values: RecoveryFields) => {
    await kratos.recover({
      ...values,
      ...flow,
      headers: {
        "x-captcha-token": await getCaptchaToken(captchaRef),
      },
    });

    setShowForm(false);
    status.setSuccess("An email containing a recovery link has been sent to the email address you provided.");
  });

  return (
    <div className="m-4 flex flex-col items-center w-full">
      <Captcha ref={captchaRef} />
      <h2 className="font-medium text-3xl text-center mb-8">Reset your password</h2>
      <div className="flex flex-col w-full max-w-xs">
        {status.error && <ErrorElement>{status.error}</ErrorElement>}
        {status.success && <Success>{status.success}</Success>}
        {showForm && <RecoveryForm onSubmit={onSubmit} submitting={processing} />}
        <p className="text-xs self-center mt-8">
          Already have an account ? <Link to={routes.login}>Login</Link>
        </p>
      </div>
    </div>
  );
};
