import React from "react";
import { Error as ErrorLayout } from "~/components/layouts";
import axios from "axios";
import { useAsyncEffect, useRegisterFlow } from "~/hooks";
import { useStore } from "~/states";
import { getValueFromSearch } from "~/utils";
import * as Sentry from "@sentry/react";
import { config } from "~/const";

import { analytics } from "~/segment";

const REGISTRATION_FAILED = "Registration Failed";

export default () => {
  const returnTo = useStore((state) => state.returnTo);
  const [flow, flowError] = useRegisterFlow({ returnTo, id: getValueFromSearch("flow") });

  useAsyncEffect(async () => {
    const provider = "google";

    if (!flow) return;

    const res = await axios
      .post(`${config.userStoreProxyUrl}/register/google`, {
        flow,
      })
      .catch(async (e) => {
        Sentry.captureException(e, { extra: { method: provider, reason: e.type } });
        await analytics.track(REGISTRATION_FAILED, { method: provider, reason: e.type });

        throw e;
      });

    window.location.assign(res.data.url);
  }, [flow]);

  if (flowError) {
    return <ErrorLayout text={flowError} goBackUrl={window.location.pathname} />;
  }

  // Waits the flow state to be loaded before displaying anything
  if (!flow) return null;

  return <div></div>;
};
