import React from "react";
import Providers from "~/providers";
import Routes from "~/routes";

export default () => (
  <Providers>
    <div className="flex flex-col bg-body min-h-screen overflow-hidden">
      <Routes />
    </div>
  </Providers>
);
