import { useState } from "react";
import { useAsyncEffect } from "./async-effect";

export const defaultGetRefreshTime = (expiresAt: Date) => {
  const now = new Date();
  return expiresAt.getTime() - now.getTime();
};

/**
 * Utility hooks to force a state fresh based on a date
 * @example
 * const [onRefresh, setDate] = useDateRefresh()
 * useEffect(() => {}, [onRefresh])
 * setDate(futureDate) // The refresh function will trigger when the date passes
 * @returns value to observe for refreshing and a function that set the refresh date
 */
export const useDateRefresh = (getRefreshTimeInMs = defaultGetRefreshTime): [number, Function] => {
  const [onRefresh, setOnRefresh] = useState(0);
  const [refreshDate, setRefreshDate] = useState<Date>();

  let refreshTimeout: number | undefined;

  useAsyncEffect(
    async () => {
      if (refreshDate) {
        clearTimeout(refreshTimeout);
        refreshTimeout = window.setTimeout(
          () => setOnRefresh((currentOnRefresh) => currentOnRefresh + 1),
          getRefreshTimeInMs(refreshDate)
        );
      }
    },
    [refreshDate],
    () => clearTimeout(refreshTimeout)
  );

  return [onRefresh, setRefreshDate];
};
