import React, { useEffect, useRef, useState } from "react";

import { Card, Error as ErrorElement, Success } from "~/components/elements";
import { Error as ErrorLayout } from "~/components/layouts";
import { config, routes } from "~/const";
import { getValueFromSearch, submitWrapper } from "~/utils";
import { kratos } from "~/services";

import { useSettingFlow, useStatus } from "~/hooks";
import { useStore } from "~/states";
import { Captcha, getCaptchaToken } from "~/components/helpers/captcha";
import { UpdatePasswordForm, UpdatePasswordFields } from "~/components/forms";
import { KratosClientError } from "~/services/kratos/errors";
import { useNavigate } from "react-router-dom";

export default () => {
  const returnTo = useStore((state) => state.returnTo);
  const status = useStatus();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [flow, flowError] = useSettingFlow({ returnTo, id: getValueFromSearch("flow") });
  const captchaRef = useRef<Captcha>(null);

  useEffect(() => {
    if (!flow?.success) {
      return;
    }

    const value = sessionStorage.getItem(flow.flow);

    if (value === "link") {
      navigate(routes.link);
    }
  }, [flow]);

  if (flowError) {
    return <ErrorLayout text={flowError} goBackUrl={window.location.pathname} />;
  }

  // Waits the flow state to be loaded before displaying anything
  if (!flow) return null;

  const submitWrapperProps = {
    setProcessing,
    processing,
    setError: status.setError,
  };

  const onSubmit = submitWrapper(submitWrapperProps, async (values: UpdatePasswordFields) => {
    await kratos
      .updateSettings({
        ...values,
        ...flow,
        headers: {
          "x-captcha-token": await getCaptchaToken(captchaRef),
        },
      })
      .then((res) => {
        status.setSuccess("Your password have been updated! You will be redirected shortly.");
        setTimeout(() => window.location.replace(res.returnTo ?? config.defaultRedirect), 3000);
      })
      .catch((error) => {
        if (KratosClientError.isKratosClientError(error)) {
          status.setError(error.message);
        } else {
          console.error(error);
          status.setError("Sorry, an error ocurred, please try again later.");
        }
      });
  });

  return (
    <div className="flex flex-col items-center w-full">
      <Captcha ref={captchaRef} />
      <h2 className="font-medium text-3xl text-center mb-8">Change your password</h2>
      <div className="w-full max-w-xs flex flex-col gap-3">
        {status.error && <ErrorElement>{status.error}</ErrorElement>}
        {status.success && <Success>{status.success}</Success>}
        <UpdatePasswordForm onSubmit={onSubmit} submitting={processing} />
        <a
          className="text-blue-600 text-xs hover:underline font-medium self-center pt-6"
          href={flow.returnTo ?? config.defaultRedirect}
        >
          Go Back
        </a>
      </div>
    </div>
  );
};
