import { kratos } from "~/services";
import axios from "axios";

type Session = Awaited<ReturnType<typeof kratos.getAuth>>;

export type AuthState = {
  active: boolean;
  session?: Session;
  verification: {
    email?: string;
    verified: boolean;
  };
};

const getVerification = (session?: Session) => ({
  email: session?.identity?.verifiable_addresses?.[0]?.value,
  verified: !!session?.identity?.verifiable_addresses?.[0]?.verified,
});

export const fetchAuth = async (): Promise<AuthState> => {
  const session = await kratos.getAuth().catch((error) => {
    if (axios.isAxiosError(error)) {
      // eslint-disable-next-line no-console
      console.debug("Unable to fetch auth state:", error);
      return undefined;
    } else {
      throw error;
    }
  });

  return {
    active: !!session?.active,
    verification: getVerification(session),
    session,
  };
};
