import React, { useEffect, useState } from "react";

import { useStatus, useVerificationFlow } from "~/hooks";
import { Info, Error as ErrorElement, Card } from "~/components/elements";
import { config } from "~/const";
import { useStore } from "~/states";
import { kratos } from "~/services";
import { getValueFromSearch, submitWrapper } from "~/utils";
import { VerificationForm, VerificationFields } from "~/components/forms";
import { useAuth } from "~/hooks/auth";

export default () => {
  const { returnTo } = useStore();
  const { data: auth } = useAuth();
  const status = useStatus();

  const [verificationComplete, setVerificationComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [flow, _flowError] = useVerificationFlow({ id: getValueFromSearch("flow") });

  const redirect = () => {
    setVerificationComplete(true);
    status.setSuccess("Thanks for verifying your email, you will be redirected shortly.");
    setTimeout(() => window.location.replace(config.defaultRedirect), 3000);
  };

  useEffect(() => {
    if (auth?.verification.verified || flow?.state === "passed_challenge") {
      redirect();
    }
  }, [auth, flow]);

  if (!auth) return null;

  const submitWrapperProps = {
    setProcessing,
    processing,
    setError: status.setError,
  };

  const onSubmit = submitWrapper(submitWrapperProps, async (values: VerificationFields) => {
    const email = values.email?.trim();

    await kratos
      .sendVerification({ returnTo, email })
      .then(() => {
        setVerificationComplete(true);
        status.setSuccess(
          `A verification email has been sent to ${email}, please click on the link in that email to verify your email address so you can login to your account. You can close this page for now.`
        );
      })
      .catch((e) => {
        status.setError(
          e.message ?? "Sorry, an error occured when sending the verification email. Please try again in a few moments."
        );
      });
  });

  return (
    <div className="m-4 flex flex-col items-center w-full">
      <h2 className="font-medium text-3xl text-center mb-8">Verify Your Account</h2>
      <div className="mb-4 w-full max-w-xs">
        {status.success && <Info>{status.success}</Info>}
        {status.error && <ErrorElement>{status.error}</ErrorElement>}
        {verificationComplete ? undefined : (
          <VerificationForm
            onSubmit={onSubmit}
            submitting={processing}
            defaultValues={{ email: getValueFromSearch("email") }}
          />
        )}
      </div>
    </div>
  );
};
