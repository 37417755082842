import { AxiosResponse } from "axios";
import { errors } from "~/const";

// src: https://github.com/ory/kratos/blob/0833321e04e9865046294b051376bed415a41441/text/id.go#L86
const kratosErrors = {
  0: "Unknown",
  4000000: "ErrorValidation",
  4000001: "ErrorValidationGeneric",
  4000002: "ErrorValidationRequired",
  4000003: "ErrorValidationMinLength",
  4000004: "ErrorValidationInvalidFormat",
  4000005: "ErrorValidationPasswordPolicyViolation",
  4000006: "ErrorValidationInvalidCredentials",
  4000007: "ErrorValidationDuplicateCredentials",
  4000008: "ErrorValidationTOTPVerifierWrong",
  4000009: "ErrorValidationIdentifierMissing",
  4000010: "ErrorValidationAddressNotVerified",
  4000011: "ErrorValidationNoTOTPDevice",
  4000012: "ErrorValidationLookupAlreadyUsed",
  4000013: "ErrorValidationNoWebAuthnDevice",
  4000014: "ErrorValidationNoLookup",
  4000015: "ErrorValidationSuchNoWebAuthnUser",
  4000016: "ErrorValidationLookupInvalid",
  4000017: "ErrorValidationMaxLength",
  4000018: "ErrorValidationMinimum",
  4000019: "ErrorValidationExclusiveMinimum",
  4000020: "ErrorValidationMaximum",
  4000021: "ErrorValidationExclusiveMaximum",
  4000022: "ErrorValidationMultipleOf",
  4000023: "ErrorValidationMaxItems",
  4000024: "ErrorValidationMinItems",
  4000025: "ErrorValidationUniqueItems",
  4000026: "ErrorValidationWrongType",
  4000027: "ErrorValidationDuplicateCredentialsOnOIDCLink",

  4010000: "ErrorValidationLogin",
  4010001: "ErrorValidationLoginFlowExpired",
  4010002: "ErrorValidationLoginNoStrategyFound",
  4010003: "ErrorValidationRegistrationNoStrategyFound",
  4010004: "ErrorValidationSettingsNoStrategyFound",
  4010005: "ErrorValidationRecoveryNoStrategyFound",
  4010006: "ErrorValidationVerificationNoStrategyFound",

  4040000: "ErrorValidationRegistration",
  4040001: "ErrorValidationRegistrationFlowExpired",

  4050000: "ErrorValidationSettings",
  4050001: "ErrorValidationSettingsFlowExpired",

  4060000: "ErrorValidationRecovery",
  4060001: "ErrorValidationRecoveryRetrySuccess",
  4060002: "ErrorValidationRecoveryStateFailure",
  4060003: "ErrorValidationRecoveryMissingRecoveryToken",
  4060004: "ErrorValidationRecoveryTokenInvalidOrAlreadyUsed",
  4060005: "ErrorValidationRecoveryFlowExpired",
  4060006: "ErrorValidationRecoveryCodeInvalidOrAlreadyUsed",

  4070000: "ErrorValidationVerification",
  4070001: "ErrorValidationVerificationTokenInvalidOrAlreadyUsed",
  4070002: "ErrorValidationVerificationRetrySuccess",
  4070003: "ErrorValidationVerificationStateFailure",
  4070004: "ErrorValidationVerificationMissingVerificationToken",
  4070005: "ErrorValidationVerificationFlowExpired",
  4070006: "ErrorValidationVerificationCodeInvalidOrAlreadyUsed",

  5000000: "ErrorSystem",
  5000001: "ErrorSystemGeneric",
} as const;

type NativeKratosError = typeof kratosErrors;
type ID = keyof NativeKratosError;
type Type = NativeKratosError[ID];

type KratosError = {
  id: ID;
  type: Type;
  text: string;
};

const defaultKratosError: KratosError = {
  id: 0,
  type: kratosErrors[0],
  text: errors.default,
} as const;

export class KratosClientError extends Error {
  public readonly id: ID;
  public readonly type: Type;

  constructor(public readonly error: KratosError, public readonly response?: AxiosResponse | undefined) {
    super(error.text);

    this.id = error.id;
    this.type = error.type;
  }

  public static fromError(error: any): KratosClientError {
    if (KratosClientError.isKratosClientError(error)) {
      return error;
    }

    if (error?.response?.data?.error?.reason === "This account was disabled.") {
      return new KratosClientError({ id: 0, type: "ErrorValidation", text: errors.disabled });
    }

    const kratosError = KratosClientError.findErrorFromResponse(error.response);
    if (!kratosError) {
      // eslint-disable-next-line no-console
      console.debug(`Sending default kratos error: ${JSON.stringify(error)}`);
      return new KratosClientError(defaultKratosError, error.response);
    }

    return new KratosClientError(kratosError, error.response);
  }

  static throwIfErrorFound(response: AxiosResponse) {
    const error = KratosClientError.findErrorFromResponse(response);

    if (error) {
      throw new KratosClientError(error);
    }
  }

  public static isKratosClientError(error: any): error is KratosClientError {
    return error instanceof KratosClientError;
  }

  private static findErrorFromResponse(response: AxiosResponse): KratosError | undefined {
    const messages = response?.data?.ui?.messages ?? [];
    const nodes: any[] = response?.data?.ui?.nodes ?? [];
    const errorMessages = [...messages, ...nodes.flatMap((node) => node?.messages)];
    const errorMessage = errorMessages.find(
      (node) => node?.text !== undefined && node?.id !== undefined && node?.type === "error"
    );

    if (!errorMessage) {
      return;
    }

    return { id: errorMessage.id, type: kratosErrors[errorMessage.id as ID], text: errorMessage.text };
  }

  public formatMessage() {
    // Error message from Kratos mentions irrelevant fields (username and phone number), is hard-coded, and they
    // don't currently offer support to override it through configuration or I18N, so we have to override manually here.
    // Reference:
    // https://github.com/ory/kratos/blob/master/schema/errors.go#L134
    // https://github.com/ory/kratos/issues/691
    // https://github.com/ory/kratos/issues/1071
    switch (this.error.type) {
      case "ErrorValidationGeneric":
        return "Sorry, the provided credentials are invalid. Please check for spelling mistakes in the email or password and try again.";
      case "ErrorValidationInvalidCredentials":
        return "Sorry, the provided credentials are invalid. Please check for spelling mistakes in the email or password and try again.";
      case "ErrorValidationDuplicateCredentials":
        return 'An account with the same email address already exists. Please login to this account using your Botpress Cloud password and then use the "Link social accounts" option to associate this existing account with the social login option you want to use.';
      case "ErrorValidationAddressNotVerified":
        return "Your account is not active yet because your email address needs to be verified first.";
      case "Unknown":
        return errors.default;
      default:
        return this.error.text;
    }
  }
}
