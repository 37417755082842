import React from "react";

import { config, errors, routes } from "~/const";
import { useAsyncEffect } from "~/hooks";
import axios from "axios";

interface Props {
  text?: string;
  goBackUrl: string;
}

export default ({ text, goBackUrl }: Props) => {
  const displayClearCookiesSuggestion = text?.includes("no resumable session found");

  useAsyncEffect(async () => {
    if (!displayClearCookiesSuggestion) {
      return;
    }

    try {
      await axios.post(`${config.kratosUrl}/proxy/clear-continuity-cookie`, {}, { withCredentials: true });
    } catch (e) {
      // silently fail
    }
    // todo: redirect to login page if successful
  }, [text]);

  return (
    <div className="flex flex-col items-center max-w-sm gap-3">
      <h2 className="text-3xl font-semibold">{errors.title}</h2>
      <h3 className="text-xl mb-4">Unable to access to your account</h3>

      <p className="mb-2 text-center text-zinc-600">Error: {text}</p>

      {displayClearCookiesSuggestion && (
        <p className="mb-2 text-center text-zinc-600">
          We’re sorry for this issue and are working on a fix. As a temporary workaround, please try clearing your
          browser cookies by{" "}
          <a
            className="text-blue-600 hover:underline font-medium pt-6"
            href="https://www.makeuseof.com/clear-chrome-firefox-edge-cookies-specific-site/"
          >
            following these instructions.
          </a>
        </p>
      )}

      <a
        className="text-blue-600 text-xs hover:underline font-medium self-center pt-6"
        href={goBackUrl ?? config.defaultRedirect}
      >
        Go Back
      </a>
    </div>
  );
};
