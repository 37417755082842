import { useState } from "react";
import { kratos } from "~/services";
import { useAsyncEffect } from "./async-effect";
import { useDateRefresh } from "./date-refresh";
import { KratosClientError } from "~/services/kratos/errors";

type SettingFlow = Awaited<ReturnType<typeof kratos.initSettingFlow>>;

type Props = {
  id?: string;
  returnTo?: string;
};

/**
 * Fetch a kratos setting flow
 * @returns a setting flow state used to update the user setting
 */
export const useSettingFlow = ({ returnTo, id }: Props): [SettingFlow | undefined, string | undefined] => {
  const [onRefresh, setRefreshDate] = useDateRefresh(kratos.refreshTimeInMs);
  const [state, setState] = useState<SettingFlow>();
  const [error, setError] = useState<string>();

  useAsyncEffect(async () => {
    let flow: SettingFlow | void;

    if (!state && id) {
      flow = await kratos.getSettingFlow({ id }).catch((e) => setError(KratosClientError.fromError(e).formatMessage()));
    } else {
      flow = await kratos
        .initSettingFlow({ returnTo })
        .catch((e) => setError(KratosClientError.fromError(e).formatMessage()));
    }

    if (flow) {
      setState(flow);
      setRefreshDate(flow.expiresAt);
    }
  }, [onRefresh]);

  return [state, error];
};
