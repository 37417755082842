import React from "react";

import { useForm } from "react-hook-form";
import { Label } from "~/components/elements";
import { ReactComponent as Mail } from "@assets/icons/mail.svg";
import { Input, InputError, InputSubmit } from "./elements";

export type Fields = {
  email: string;
};

type Props = {
  onSubmit: (data: Fields) => void;
  submitting: boolean;
  defaultValues?: Partial<Fields>;
};

export default ({ submitting, onSubmit, defaultValues }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Fields>({ defaultValues });

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div className="group">
        <Input
          {...register("email", { required: true })}
          autoComplete="false"
          tabIndex={0}
          placeholder="Email"
          className="h-12"
          error={!!errors.email}
          icon={
            <Mail
              height={"24px"}
              className="absolute left-2 text-zinc-400 pointer-events-none group-focus-within:text-zinc-500 z-20"
            />
          }
        />
        <InputError display={!!errors.email} message="Email is required" />
      </div>
      <InputSubmit tabIndex={0} variant="blue" className="h-12 mt-8 group" disabled={submitting}>
        <div className="flex justify-center items-center gap-2">
          {submitting ? "Please wait..." : "Send verification email"}
        </div>
      </InputSubmit>
    </form>
  );
};
