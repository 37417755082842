import React from "react";
import { config } from "~/const";

export const getCaptchaToken = async (captchaRef: React.RefObject<Captcha>) => {
  if (!captchaRef?.current) {
    throw new Error("Captcha component not available yet.");
  }

  try {
    return await captchaRef.current.getToken();
  } catch (e: any) {
    throw new Error(`Sorry, an error occurred when trying to verify your submission: ${e.message}`);
  }
};

export class CaptchaError extends Error {}

export class Captcha extends React.Component {
  public async getToken(): Promise<string> {
    return new Promise((resolve: (token: string) => void, reject: (error: Error) => void) => {
      if (!window["grecaptcha"]) {
        reject(new CaptchaError("An unexpected problem occurred and the captcha library didn't load correctly"));
        return;
      }

      grecaptcha.ready(() => {
        try {
          grecaptcha.execute(config.captchaSiteKey, { action: "submit" }).then(
            (token) => resolve(token),
            () =>
              reject(new CaptchaError("An unexpected problem occurred and the captcha library didn't load correctly"))
          );
        } catch (e) {
          reject(new CaptchaError("An unexpected problem occurred and the captcha library didn't load correctly"));
        }
      });
    });
  }

  render() {
    return <></>;
  }
}
