import React from "react";
import { ReactComponent as Logo } from "@assets/logo.svg";

interface Props {
  className?: string;
  height?: number;
}

export default ({ className, height }: Props) => (
  <a href="https://botpress.com" target="_blank" rel="noopener noreferrer">
    <Logo width="100%" height={height ?? 16} className={className} />
  </a>
);
