import { useQuery } from "@tanstack/react-query";
import { auth as authService } from "~/services";
import { queryKeys } from "~/const";

export const useAuth = () =>
  useQuery({
    queryKey: queryKeys.auth,
    queryFn: authService.fetchAuth,
    staleTime: 1000,
  });
