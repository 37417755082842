import React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default ({ children, className }: Props) => (
  <div className={`shadow-md p-8 rounded-md bg-white ${className ?? ""}`}>{children}</div>
);
