import React, { useEffect, useRef } from "react";

import { Routes, Route, useLocation } from "react-router-dom";

import {
  LoginPage,
  RegistrationPage,
  RegistrationPageGoogle,
  RecoveryPage,
  VerificationPage,
  LogoutPage,
  SettingsPage,
  ErrorPage,
  LinkPage,
} from "~/components/pages";
import { AuthRedirect, AuthPrivileged } from "~/components/controls";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { analytics } from "./segment";
import { isKnownPath, namesByPath, routes } from "./const";
import { Background } from "./components/layouts";

const PageContent = ({ children }: { children: React.ReactNode }) => (
  <div className="grid-overlay flex justify-center">{children}</div>
);

export default () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if (!isKnownPath(pathname)) {
      return;
    }

    const pageName = namesByPath[pathname];
    analytics.page({ path: pageName });
  }, [location]);

  const ref = useRef();

  return (
    <>
      <Background path={location.pathname} />
      <div className="flex flex-col flex-1 w-full h-full mt-8 items-center justify-center z-20 relative">
        <TransitionGroup className="grid w-full h-full">
          <CSSTransition nodeRef={ref} key={location.key} classNames="page" timeout={500}>
            <Routes location={location}>
              <Route
                path="*"
                element={
                  <PageContent>
                    <AuthRedirect allowRefresh>
                      <LoginPage />
                    </AuthRedirect>
                  </PageContent>
                }
              />
              <Route
                path={routes.registration}
                element={
                  <PageContent>
                    <AuthRedirect>
                      <RegistrationPage />
                    </AuthRedirect>
                  </PageContent>
                }
              />
              <Route
                path={routes["registration-google"]}
                element={
                  <PageContent>
                    <AuthRedirect>
                      <RegistrationPageGoogle />
                    </AuthRedirect>
                  </PageContent>
                }
              />
              <Route
                path={routes.recovery}
                element={
                  <PageContent>
                    <AuthRedirect>
                      <RecoveryPage />
                    </AuthRedirect>
                  </PageContent>
                }
              />
              <Route
                path={routes.verification}
                element={
                  <PageContent>
                    <VerificationPage />
                  </PageContent>
                }
              />
              <Route
                path={routes.logout}
                element={
                  <PageContent>
                    <LogoutPage />
                  </PageContent>
                }
              />
              <Route
                path={routes.settings}
                element={
                  <PageContent>
                    <AuthPrivileged returnTo={routes.settings}>
                      <SettingsPage />
                    </AuthPrivileged>
                  </PageContent>
                }
              />
              <Route
                path={routes.error}
                element={
                  <PageContent>
                    <ErrorPage />
                  </PageContent>
                }
              />
              <Route
                path={routes.link}
                element={
                  <PageContent>
                    <AuthPrivileged returnTo={routes.link}>
                      <LinkPage />
                    </AuthPrivileged>
                  </PageContent>
                }
              />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
};
