import React, { useRef } from "react";

import { useForm } from "react-hook-form";
import { Label, Spinner } from "~/components/elements";
import { ReactComponent as Mail } from "@assets/icons/mail.svg";
import cx from "classnames";

import { ReactComponent as Password } from "@assets/icons/password.svg";
import { Input, InputError, InputSubmit } from "./elements";

export type Fields = {
  password: string;
  passwordConfirmation: string;
};

type Props = {
  onSubmit: (data: Fields) => void;
  submitting: boolean;
};

export default ({ onSubmit, submitting }: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Fields>();

  const password = useRef({});

  password.current = watch("password", "");

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
      <div className="group">
        <Input
          {...register("password", { required: true })}
          placeholder="Password"
          type="password"
          autoComplete="false"
          tabIndex={0}
          className={cx("h-12")}
          error={!!errors.password}
          icon={
            <Password
              height={"24px"}
              className={cx("absolute left-2 text-zinc-400 pointer-events-none group-focus-within:text-zinc-500 z-20")}
            />
          }
        />
        <InputError display={!!errors.password} message="Password is required" />
      </div>
      <div className="group">
        <Input
          {...register("passwordConfirmation", { required: true, validate: (value) => value === password.current })}
          placeholder="Confirm Password"
          type="password"
          autoComplete="false"
          tabIndex={0}
          className={cx("h-12")}
          error={!!errors.passwordConfirmation}
          icon={
            <Password
              height={"24px"}
              className={cx("absolute left-2 text-zinc-400 pointer-events-none group-focus-within:text-zinc-500 z-20")}
            />
          }
        />
        <InputError display={!!errors.passwordConfirmation} message="Password doesn't match" />
      </div>
      <div className="group">
        <InputSubmit tabIndex={0} variant="blue" className="h-12 group" disabled={submitting}>
          <div className="flex justify-center items-center gap-2">
            {submitting && <Spinner />}
            {submitting ? "Please wait..." : "Change Password"}
          </div>
        </InputSubmit>
      </div>
    </form>
  );
};
