import { useState } from "react";

type StatusState = {
  error?: string;
  success?: string;
  warning?: string;
};

/**
 * utility hook to manage a status
 * @returns status object
 */
export const useStatus = (defaultState = {}) => {
  const [status, setStatus] = useState<StatusState>(defaultState);

  return {
    ...status,
    setError: (error: string) => setStatus({ error }),
    setSuccess: (success: string) => setStatus({ success }),
    setWarning: (warning: string) => setStatus({ warning }),
    reset: () => setStatus({}),
  };
};
