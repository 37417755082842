import React from "react";

type Intent = "success" | "info" | "caution" | "danger";

interface CalloutProps {
  intent: Intent;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const intentColors: { [key in Intent]: { text: string; background: string } } = {
  // Note: We have the define the Tailwind CSS classes explictly as Tailwind does tree shaking of styles by searching for CSS classes
  // used in the code at build time, and thus doesn't support constructing references to CSS classes dynamically.
  // Reference: https://v2.tailwindcss.com/docs/just-in-time-mode#arbitrary-value-support
  success: { text: "text-green-600", background: "bg-green-100" },
  info: { text: "text-blue-600", background: "bg-blue-100" },
  caution: { text: "text-yellow-800", background: "bg-yellow-100" },
  danger: { text: "text-red-600", background: "bg-red-100" },
};

export const Callout = ({ intent, icon: Icon, children }: React.PropsWithChildren<CalloutProps>) => {
  const color = intentColors[intent];

  return (
    <div
      className={`callout ${color.background} ${color.text} rounded px-3 py-2 mb-4 max-w-lg flex items-center justify-start`}
    >
      {Icon && <Icon className="mr-2 shrink-0 grow-0" height={"16px"} width={"16px"} />}
      <p className="text-sm">{children}</p>
    </div>
  );
};
