import React, { InputHTMLAttributes } from "react";
import cx from "classnames";

type Props = { variant: "indigo" | "blue"; icon?: React.ReactNode } & InputHTMLAttributes<HTMLButtonElement>;

export default ({ variant = "blue", ...props }: Props) => (
  <div className="group relative flex items-center">
    {props.icon}
    <button
      {...props}
      type="submit"
      className={cx(
        {
          "hover:bg-indigo-50 rounded-md border-indigo-500 text-indigo-600": variant === "indigo",
        },
        {
          "hover:bg-blue-50 rounded-md border-blue-500 text-blue-600": variant === "blue",
        },
        "text-sm font-medium shadow-sm disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer border w-full z-10",
        props.className
      )}
    />
  </div>
);
