import React, { InputHTMLAttributes } from "react";
import cx from "classnames";

type Props = {
  icon?: React.ReactNode;
  error?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export default React.forwardRef<HTMLInputElement, Props>(({ error, icon, className, ...props }, ref) => (
  <div className="group relative flex items-center">
    {icon}
    <input
      ref={ref}
      {...props}
      className={cx(
        "focus:ring-1  focus:outline-none w-full text-sm leading-6 rounded-md ring-1  shadow-sm  transition duration-300 z-10",
        error
          ? "focus:ring-red-600 ring-red-500 hover:bg-red-50"
          : "focus:ring-zinc-600 ring-gray-200 placeholder-zinc-400 text-zinc-900 hover:bg-zinc-50",
        className,
        { "pl-11": icon }
      )}
    />
  </div>
));
