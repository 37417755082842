import React, { ButtonHTMLAttributes } from "react";
import cx from "classnames";

type Props = {
  icon: string;
  text: string;
  variant?: "zinc" | "blue";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default ({ variant = "zinc", ...props }: Props) => (
  <button
    {...props}
    className={cx(
      "group flex items-center justify-center cursor-pointer border rounded-md text-sm font-medium px-4 py-3 shadow-sm",
      { "hover:bg-blue-50 border-blue-500 text-blue-600 hover:text-blue-700": variant === "blue" },
      { "hover:bg-zinc-100 border-zinc-500 text-zinc-700": variant === "zinc" },
      props.className
    )}
  >
    <img className="w-4 h-4 mr-3" src={props.icon} alt="" />
    <div className="text-sm">{props.text}</div>
  </button>
);
