import { useState } from "react";
import { kratos } from "~/services";
import { KratosClientError } from "~/services/kratos/errors";
import { useAsyncEffect } from "./async-effect";

type VerificationFlow = Awaited<ReturnType<typeof kratos.getVerificationFlow>>;

type Props = {
  id?: string;
};

/**
 * Fetch a kratos verification flow
 * @returns a verification flow state
 */
export const useVerificationFlow = ({ id }: Props): [VerificationFlow | undefined, string | undefined] => {
  const [state, setState] = useState<VerificationFlow>();
  const [error, setError] = useState<string>();

  useAsyncEffect(async () => {
    let flow: VerificationFlow | void;

    if (id) {
      flow = await kratos
        .getVerificationFlow({ id })
        .catch((e) => setError(KratosClientError.fromError(e).formatMessage()));

      if (flow) {
        setState(flow);
      }
    }
  }, []);

  return [state, error];
};
