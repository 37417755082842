import { useState } from "react";
import { kratos } from "~/services";
import { KratosClientError } from "~/services/kratos/errors";
import { useAsyncEffect } from "./async-effect";
import { useDateRefresh } from "./date-refresh";

export type RegisterFlow = Awaited<ReturnType<typeof kratos.initRegisterFlow>>;

type Props = {
  returnTo?: string;
  id?: string;
};

/**
 * Fetch a kratos register flow
 * @returns a register flow state used to register
 */
export const useRegisterFlow = ({ returnTo, id }: Props): [RegisterFlow | undefined, string | undefined] => {
  const [onRefresh, setRefreshDate] = useDateRefresh(kratos.refreshTimeInMs);
  const [state, setState] = useState<RegisterFlow>();
  const [error, setError] = useState<string>();

  useAsyncEffect(async () => {
    let flow: RegisterFlow | void;

    if (!state && id) {
      flow = await kratos
        .getRegisterFlow({ id })
        .catch((e) => setError(KratosClientError.fromError(e).formatMessage()));
    } else {
      flow = await kratos
        .initRegisterFlow({ returnTo })
        .catch((e) => setError(KratosClientError.fromError(e).formatMessage()));
    }

    if (flow) {
      setState(flow);
      setRefreshDate(flow.expiresAt);
    }
  }, [onRefresh]);

  return [state, error];
};
