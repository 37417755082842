import React from "react";

import { Catch } from "~/components/controls";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

interface props {
  children: React.ReactNode;
}

export default (props: props) => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Catch>{props.children}</Catch>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
);
